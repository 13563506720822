var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"videoAds"},[_c('a-breadcrumb',{staticClass:"breadcrum"},[_c('a-breadcrumb-item',[_c('a',{attrs:{"href":"/"}},[_vm._v(_vm._s(_vm.client.ClientName))])]),_c('a-breadcrumb-item',[_c('a',{attrs:{"href":"/products"}},[_vm._v("Products")])]),_c('a-breadcrumb-item',[_vm._v(_vm._s(_vm.$route.meta.surveyTitle))])],1),_c('div',{staticClass:"authoring"},[_c('ProgressBar',{attrs:{"stepIndex":_vm.stepIndex,"surveyID":_vm.surveyID,"stepsType":_vm.surveyTypeID === 102 ? 'Reticle Alignment Test' : 'video'}}),_c('section',{staticClass:"stepAuthor"},[_c('div',{staticClass:"questions"},[_c('div',{staticClass:"quizTitle centerText",domProps:{"innerHTML":_vm._s(
            _vm.surveyTypeID === 102
              ? _vm.langObj['s2-upload'].quizReticle
              : _vm.langObj['s2-upload'].quiz
          )}}),_c('div',{staticClass:"creatives"},[_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"tab upload",class:{ disabled: _vm.tabDisabled, active: _vm.showUploadPanel },on:{"click":_vm.addNewStim}},[_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":"cloud-upload-alt"}})],1),_c('div',{staticClass:"iconDes",domProps:{"innerHTML":_vm._s(_vm.langObj['s2-upload'].addCreative)}})]),_vm._l((_vm.creatives),function(creative,index){return _c('div',{key:index,staticClass:"tab",class:{
                active: _vm.curTabIdex === index && _vm.showUploadPanel === false,
                processing: creative.coverImage === '',
                disabled: _vm.tabDisabled,
              },on:{"click":function($event){return _vm.viewStim(index)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(creative.coverImage === ''),expression:"creative.coverImage === ''"}]},[_c('div',{staticClass:"icon"},[_c('a-icon',{attrs:{"type":"loading"}})],1),_c('div',{staticClass:"iconDes",domProps:{"innerHTML":_vm._s(_vm.langObj['s2-upload'].processingCreative)}})]),(_vm.$route.meta.surveyTypeID !== 102)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(creative.coverImage !== ''),expression:"creative.coverImage !== ''"}],staticClass:"thumbHolder"},[_c('img',{attrs:{"src":creative.coverImage}})]):(creative.coverImage !== '')?_c('div',{staticClass:"thumbHolder flex items-center justify-center"},[_c('strong',[_vm._v(_vm._s(creative.CellType !== undefined && creative.CellType !== "" ? creative.CellType : "Video " + (index + 1)))])]):_vm._e()])})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showUploadPanel),expression:"showUploadPanel"}],staticClass:"creativeUpload"},[_c('LocalFileUpload',{attrs:{"surveyID":_vm.surveyID,"langObj":_vm.langObj},on:{"fileUpdateEvent":_vm.localFileInfoUpdate}}),_c('span',{staticClass:"cursive or",domProps:{"innerHTML":_vm._s(_vm.langObj['s2-upload'].or)}},[_vm._v("OR")]),_c('YouTubeUpload',{attrs:{"langObj":_vm.langObj},on:{"getYouTubeLink":_vm.processYTLink}}),(
                _vm.$route.meta.surveyTypeID !== 102
                  ? _vm.creatives.length >= 5
                  : _vm.creatives.length >= 3
              )?_c('div',{staticClass:"creativeUpload__stim-limit"},[_c('p',[_vm._v(" You have reached the limit of "+_vm._s(_vm.$route.meta.surveyTypeID !== 102 ? 5 : 3)+" videos. ")])]):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showUploadPanel),expression:"!showUploadPanel"}],staticClass:"creativeConfig"},[_c('CreativeConfig',{attrs:{"videoObj":_vm.creatives[_vm.curTabIdex],"surveyID":_vm.surveyID,"stimID":_vm.stimID,"langObj":_vm.langObj,"userId":_vm.user !== null ? _vm.user.EncrypteduserID : null},on:{"stimSavedEvent":_vm.stimSaved,"stimDeletedEvent":_vm.stimDeleted,"onConfigUpdate":_vm.configUpdate,"stopUpload":_vm.stopUpload,"newStimId":_vm.newStimId}})],1)])]),_c('CompletionProgress',{attrs:{"checkList":_vm.quizs}})],1),_c('StepNaviBar',{attrs:{"stepIndex":_vm.stepIndex,"nextDisable":_vm.nextDisable,"prevDisable":_vm.prevDisable,"prevBtnTooltip":"Back to General Settings","nextBtnTooltip":"Go to Brand Strategy","stepsType":_vm.surveyTypeID === 102 ? 'Reticle Alignment Test' : 'video'},on:{"nextHandler":_vm.nextStep,"prevHandler":_vm.prevStep}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }