var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user"},[_c('a-breadcrumb',{staticClass:"breadcrum breadcrum--white"},[(_vm.user !== null && _vm.user.SkinIdentifier !== 'pg')?[_c('a-breadcrumb-item',[_c('a',{attrs:{"href":"/"}},[_vm._v(_vm._s(_vm.client ? _vm.client.ClientName : ""))])]),_c('a-breadcrumb-item',[_c('router-link',{attrs:{"to":"/user-management"}},[_vm._v("User Management")])],1),_c('a-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.pageTitle))])]:_vm._e()],2),_c('h1',[_vm._v(_vm._s(_vm.pageTitle))]),_c('div',{staticClass:"user__item"},[_c('a-row',{staticClass:"user__item-title",attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('h2',[_vm._v("General informations")])]),_c('a-col',{staticClass:"flex justify-end",attrs:{"span":12}},[(_vm.informationsSuccessUpdated)?_c('span',{staticClass:"user__item-title-info"},[_c('font-awesome-icon',{attrs:{"icon":"check"}}),_vm._v("Informations are success updated!")],1):_vm._e()])],1),_c('div',{staticClass:"user__item-content",class:{ 'show-overlay': _vm.saveLoadingStatus }},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('label',{staticClass:"lineLable"},[_vm._v("First Name*")]),_c('div',{staticClass:"position-r"},[(_vm.showSkeleton)?_c('Skeleton',{attrs:{"borderRadius":4}}):_vm._e(),_c('a-input',{class:{
                'error-field':
                  _vm.userInfo.firstName === '' && _vm.emptyFieldsValidation,
              },attrs:{"placeholder":""},model:{value:(_vm.userInfo.firstName),callback:function ($$v) {_vm.$set(_vm.userInfo, "firstName", $$v)},expression:"userInfo.firstName"}})],1)]),_c('a-col',{attrs:{"span":12}},[_c('label',{staticClass:"lineLable"},[_vm._v("Last Name*")]),_c('div',{staticClass:"position-r"},[(_vm.showSkeleton)?_c('Skeleton',{attrs:{"borderRadius":4}}):_vm._e(),_c('a-input',{class:{
                'error-field':
                  _vm.userInfo.lastName === '' && _vm.emptyFieldsValidation,
              },attrs:{"placeholder":""},model:{value:(_vm.userInfo.lastName),callback:function ($$v) {_vm.$set(_vm.userInfo, "lastName", $$v)},expression:"userInfo.lastName"}})],1)])],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('label',{staticClass:"lineLable"},[_vm._v("Email*")]),_c('div',{staticClass:"position-r"},[(_vm.showSkeleton)?_c('Skeleton',{attrs:{"borderRadius":4}}):_vm._e(),_c('a-input',{class:{
                'error-field': _vm.userInfo.email === '' && _vm.emptyFieldsValidation,
              },attrs:{"placeholder":""},model:{value:(_vm.userInfo.email),callback:function ($$v) {_vm.$set(_vm.userInfo, "email", $$v)},expression:"userInfo.email"}})],1)]),_c('a-col',{attrs:{"span":12}},[_c('label',{staticClass:"lineLable"},[_vm._v("Company*")]),_c('div',{staticClass:"position-r"},[(_vm.showSkeleton)?_c('Skeleton',{attrs:{"borderRadius":4}}):_vm._e(),_c('a-input',{class:{
                'error-field':
                  _vm.userInfo.company === '' && _vm.emptyFieldsValidation,
              },attrs:{"placeholder":""},model:{value:(_vm.userInfo.company),callback:function ($$v) {_vm.$set(_vm.userInfo, "company", $$v)},expression:"userInfo.company"}})],1)])],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('label',{staticClass:"lineLable"},[_vm._v("Job title*")]),_c('div',{staticClass:"position-r"},[(_vm.showSkeleton)?_c('Skeleton',{attrs:{"borderRadius":4}}):_vm._e(),_c('a-input',{class:{
                'error-field':
                  _vm.userInfo.jobTitle === '' && _vm.emptyFieldsValidation,
              },attrs:{"placeholder":""},model:{value:(_vm.userInfo.jobTitle),callback:function ($$v) {_vm.$set(_vm.userInfo, "jobTitle", $$v)},expression:"userInfo.jobTitle"}})],1)]),_c('a-col',{attrs:{"span":12}},[_c('label',{staticClass:"lineLable"},[_vm._v("User Type*")]),_c('div',{staticClass:"position-r"},[(_vm.showSkeleton)?_c('Skeleton',{attrs:{"borderRadius":4}}):_vm._e(),_c('a-select',{key:_vm.selectDropdownsKey,staticClass:"fullWidth",class:{
                'error-field':
                  _vm.userInfo.UserTypeID === '' && _vm.emptyFieldsValidation,
              },attrs:{"option-filter-prop":"children","show-search":"","placeholder":"Please select","default-value":_vm.userInfo.UserTypeID || undefined},on:{"change":_vm.selectUserType}},_vm._l((_vm.userTypes),function(type,index){return _c('a-select-option',{key:index,attrs:{"value":type.id}},[_vm._v(" "+_vm._s(type.title)+" ")])}),1)],1)])],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('label',{staticClass:"lineLable"},[_vm._v("Client*")]),_c('div',{staticClass:"position-r"},[(_vm.showSkeleton)?_c('Skeleton',{attrs:{"borderRadius":4}}):_vm._e(),_c('a-select',{class:{
                'error-field':
                  _vm.userInfo.ClientIDs.length === 0 && _vm.emptyFieldsValidation,
              },staticStyle:{"width":"100%"},attrs:{"options":_vm.clients,"mode":"multiple","placeholder":"Please select","filterOption":false},model:{value:(_vm.userInfo.ClientIDs),callback:function ($$v) {_vm.$set(_vm.userInfo, "ClientIDs", $$v)},expression:"userInfo.ClientIDs"}})],1)])],1)],1)],1),_c('div',{staticClass:"user__item"},[_c('a-row',{staticClass:"user__item-title",attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('h2',[_vm._v("Password")])])],1),_c('div',{staticClass:"user__item-content",class:{ 'show-overlay': _vm.saveLoadingStatus }},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('label',{staticClass:"lineLable"},[_vm._v("Password*")]),_c('div',{staticClass:"position-r"},[(_vm.showSkeleton)?_c('Skeleton',{attrs:{"borderRadius":4}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.userInfo.password !== null || _vm.repeatPassword !== null),expression:"userInfo.password !== null || repeatPassword !== null"}],staticClass:"icon fixRight iconBtn",on:{"click":_vm.togglePassword}},[_c('font-awesome-icon',{attrs:{"icon":_vm.pswdVisible ? 'eye-slash' : 'eye'}})],1),_c('a-input',{class:{
                'error-field':
                  (_vm.userInfo.password === null || _vm.userInfo.password === '') &&
                  _vm.emptyFieldsValidation,
              },attrs:{"placeholder":"","type":_vm.pswdType},model:{value:(_vm.userInfo.password),callback:function ($$v) {_vm.$set(_vm.userInfo, "password", $$v)},expression:"userInfo.password"}})],1)]),_c('a-col',{attrs:{"span":12}},[_c('label',{staticClass:"lineLable"},[_vm._v("Repeat Password*")]),_c('div',{staticClass:"position-r"},[(_vm.showSkeleton)?_c('Skeleton',{attrs:{"borderRadius":4}}):_vm._e(),_c('a-input',{class:{
                'error-field':
                  (_vm.repeatPassword === null || _vm.repeatPassword === '') &&
                  _vm.emptyFieldsValidation,
              },attrs:{"placeholder":"","type":_vm.pswdType},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}})],1)])],1)],1)],1),_c('div',{staticClass:"user__item"},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[(_vm.formValidations.length > 0)?_c('ul',{staticClass:"validation-list"},_vm._l((_vm.formValidations),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()]),_c('a-col',{staticClass:"flex justify-end",attrs:{"span":12}},[_c('a-button',{staticClass:"mr-10",on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c('a-button',{staticClass:"flex items-center",attrs:{"type":"primary"},on:{"click":_vm.formValidation}},[(_vm.saveLoadingStatus)?_c('div',{staticClass:"flex"},[_c('i',{staticClass:"circle-loader"}),_vm._v(" Please wait... ")]):_c('span',[_vm._v("Save")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }