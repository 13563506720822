<template>
  <section>
    <h4 class="h4Title centerText" v-html="langObj['s2-upload'].local1"></h4>
    <a-upload-dragger
      name="file"
      accept=".mp4"
      :multiple="false"
      :showUploadList="false"
      :before-upload="beforeUpload"
      :customRequest="uploadAWS"
    >
      <p class="ant-upload-drag-icon">
        <!-- <a-icon type="inbox" /> -->
        <img src="/images/icons/videoUpload.svg" class="uploadIcon" />
      </p>
      <p class="ant-upload-text" v-html="langObj['s2-upload'].local2"></p>
    </a-upload-dragger>
  </section>
</template>

<script>
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import {
  S3Client,
  ListObjectsCommand,
  PutObjectCommand,
} from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from "uuid";
import * as jsUtils from "@/utils/jsUtils.js";

export default {
  name: "LocalFileUpload",
  props: {
    surveyID: String,
    langObj: {},
  },
  data() {
    return {
      fileURL: "",
      fileName: "",
      allowUploading: true,
      vDuration: null,
    };
  },
  methods: {
    handleChange(info) {
      const status = info.file.status;
      if (status === "done") {
        this.$message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file) {
      if (file.type !== "video/mp4") {
        this.$message.error("You can only upload video in .mp4 format!");
        this.allowUploading = false;
      }

      const isLt500M = file.size / 1024 / 1024 < 500;
      if (!isLt500M) {
        this.$message.error("Video must smaller than 500MB!");
        this.allowUploading = false;
      }
    },
    uploadAWS(info) {
      if (!this.allowUploading) {
        return;
      }
      const file = info.file;
      const assetID = uuidv4();

      // if(data.AdDuration > 10){
      //     this.stopUpload(data.assetID)
      // }

      function validateFile(file) {
        var video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          if (video.duration < 481) {
            createStim(video.duration);

            // create s3 client
            const REGION = "us-east-1"; //REGION
            const s3 = new S3Client({
              region: REGION,
              credentials: fromCognitoIdentityPool({
                client: new CognitoIdentityClient({ region: REGION }),
                identityPoolId:
                  "us-east-1:a8e31567-50e5-428c-8614-84f132d4ec6f", // IDENTITY_POOL_ID
              }),
            });
            const bucketName = "hotspexstudio"; //BUCKET_NAME

            // create albumn/floder
            const albumName = `S${jsUtils.getSurveyUnEncryptId()}`;
            let albumPhotosKey = encodeURIComponent(albumName) + "/";

            const uploadFile = () => {
              console.info("bucket existed, we can upload to bucket directly");

              try {
                let fileName = assetID + "." + file.type.replace(/(.*)\//g, "");
                const photoKey = albumPhotosKey + fileName;
                const uploadParams = {
                  Bucket: bucketName,
                  Key: photoKey,
                  Body: file,
                };

                try {
                  s3.send(new PutObjectCommand(uploadParams)).then(
                    () => {
                      // process data.
                      const url = `https://${bucketName}.s3.amazonaws.com/${albumPhotosKey}${fileName}`;
                      self.$emit("fileUpdateEvent", {
                        name: file.name,
                        url: url,
                        assetID: assetID,
                      });
                    },
                    (error) => {
                      // error handling.
                      console.log("upload error", error);
                    }
                  );
                } catch (err) {
                  return alert(
                    "There was an error uploading your photo: ",
                    err.message
                  );
                }
              } catch (err) {
                if (!file) {
                  return alert("Choose a file to upload first.");
                }
              }
            };

            const createFolder = () => {
              try {
                const params = { Bucket: bucketName, Key: albumPhotosKey };
                s3.send(new PutObjectCommand(params)).then(() => {
                  uploadFile();
                });
              } catch (err) {
                return alert(
                  "There was an error creating your album: " + err.message
                );
              }
            };

            const checkAlumb = () => {
              s3.send(
                new ListObjectsCommand({
                  Prefix: albumPhotosKey,
                  Bucket: bucketName,
                })
              ).then((data) => {
                if (data.Contents === undefined) {
                  createFolder();
                } else {
                  uploadFile();
                }
              });
            };
            checkAlumb();
          } else {
            self.$error({
              title: "Video problem detected",
              content: () => (
                <div>
                  The video you have uploaded is too long for our standard
                  testing framework. <br />
                  <br /> Please contact Hotspex support to learn more about
                  engaging with our research team and gain access to variety of
                  custom research tools and methodologies. <br />
                  <br /> In order to proceed with this test, you will need to
                  upload video shorter than 480 seconds.
                </div>
              ),
            });
          }
        };

        video.src = URL.createObjectURL(file);
      }

      const self = this;
      function createStim(duration) {
        self.$emit("fileUpdateEvent", {
          name: file.name,
          url: "",
          assetID: assetID,
          AdDuration: duration,
        });
      }

      validateFile(file);
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  padding: 1em;
  border: 1px solid #e2e2e2;
  background-color: #f3f3f3;
  border-radius: 8px;
  display: grid;
  grid-template-rows: auto 240px;

  .uploadIcon {
    height: 80px;
  }

  .ant-upload.ant-upload-drag p.ant-upload-text {
    color: var(--iconBGGrey);
  }
}
</style>
