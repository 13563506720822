<template>
  <div class="define-report">
    <a-breadcrumb
      class="breadcrum breadcrum--white"
      v-if="user !== null && user.SkinIdentifier !== 'pg'"
    >
      <template>
        <a-breadcrumb-item>
          <a href="/">{{
            client ? client.ClientName : ""
          }}</a></a-breadcrumb-item
        >
        <a-breadcrumb-item
          ><router-link to="/define-report"
            >Define Report</router-link
          ></a-breadcrumb-item
        >
      </template>
    </a-breadcrumb>
    <h1>Define report</h1>

    <Loader text="Loading data..." :paddingTop="150" v-if="loading"></Loader>

    <div v-else>
      <div class="define-report__item">
        <a-row :gutter="16" class="define-report__item-title">
          <a-col :span="24"><h2>Details</h2></a-col>
        </a-row>
        <div class="define-report__item-content">
          <div class="define-report__item-details">
            <label>Name</label>
            <p>
              {{
                reportRequirements.surveyName !== null &&
                reportRequirements.surveyName !== ""
                  ? reportRequirements.surveyName
                  : "/"
              }}
            </p>
          </div>
          <div class="define-report__item-details">
            <label>Learning objectives</label>
            <p>
              {{
                reportRequirements.learning_objective !== null &&
                reportRequirements.learning_objective !== ""
                  ? reportRequirements.learning_objective
                  : "/"
              }}
            </p>
          </div>
          <div class="define-report__item-details">
            <label>Internal project</label>
            <p>
              {{
                reportRequirements.internal_project !== null &&
                reportRequirements.internal_project !== ""
                  ? reportRequirements.internal_project
                  : "/"
              }}
            </p>
          </div>
          <div class="define-report__item-details">
            <label>Tags</label>
            <p>
              {{
                reportRequirements.tags !== null &&
                reportRequirements.tags !== ""
                  ? reportRequirements.tags
                  : "/"
              }}
            </p>
          </div>
          <div class="define-report__item-details">
            <label>Cost center</label>
            <p>
              {{
                reportRequirements.costCenter !== null &&
                reportRequirements.costCenter !== ""
                  ? reportRequirements.costCenter
                  : "/"
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="define-report__item">
        <a-row :gutter="16" class="define-report__item-title">
          <a-col :span="24"><h2>Key messages</h2></a-col>
        </a-row>
        <div class="define-report__item-content">
          <div class="define-report__item-key-messages-wrapper">
            <h3>User key messages</h3>
            <span v-if="reportRequirements.userkeymessage.length === 0">/</span>
            <div
              v-for="(item, index) in reportRequirements.userkeymessage"
              :key="index"
              class="general-checkbox general-checkbox--disabled"
            >
              <input
                type="checkbox"
                :id="index"
                value=""
                disabled
                checked
              /><label :for="index">{{ item.keyMessages }}</label>
            </div>
          </div>
          <div class="define-report__item-key-messages-wrapper">
            <h3>Category key messages</h3>
            <span v-if="reportRequirements.categoryKeymessage.length === 0"
              >/</span
            >
            <div
              v-for="item in reportRequirements.categoryKeymessage"
              :key="item.id"
              class="general-checkbox"
            >
              <input
                type="checkbox"
                :id="item.id"
                v-model="item.selected"
                value=""
                :checked="item.selected"
              /><label :for="item.id">{{ item.keyMessages }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="define-report__item">
        <a-row :gutter="16" class="define-report__item-title">
          <a-col :span="24"><h2>Further information</h2></a-col>
        </a-row>
        <div
          class="
            define-report__item-content
            define-report__item-content--further-info
          "
        >
          <label
            >Please provide any further information that will help us customize
            the report for you</label
          >
          <a-textarea
            v-model="reportRequirements.importantInfo"
            :auto-size="{ minRows: 4 }"
          />
        </div>
      </div>
      <div data-v-0d1ac07c="" class="define-report__item">
        <div class="ant-row">
          <div class="flex justify-end ant-col ant-col-24">
            <a-button @click="$router.push('/')"> Cancel </a-button>
            <a-button
              type="primary"
              class="ml-10"
              @click="save"
              :disabled="disableSaveBtn"
            >
              Save
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import Loader from "@/components/general/Loader.vue";
import _ from "lodash";

export default {
  name: "DefineReport",
  components: {
    Loader,
  },
  props: {},
  data() {
    return {
      user: null,
      client: null,
      reportRequirements: {
        surveyName: null,
        learning_objective: null,
        internal_project: null,
        tags: null,
        costCenter: null,
        userkeymessage: [],
        categoryKeymessage: [],
        importantInfo: null,
      },
      tempReportRequirements: {},
      loading: true,
      surveyID: null,
    };
  },
  computed: {
    disableSaveBtn() {
      let objectsCompare = _.isEqual(
        this.reportRequirements,
        this.tempReportRequirements
      );
      return objectsCompare;
    },
  },
  methods: {
    surveyReportRequirements() {
      const getSurveyReportRequirements = new Promise((resolve) => {
        wsUtils.GetSurveyReportRequirements(
          {
            surveyId: this.surveyID,
          },
          resolve
        );
      });

      getSurveyReportRequirements.then((data) => {
        this.reportRequirements = data;
        this.tempReportRequirements = JSON.parse(
          JSON.stringify(this.reportRequirements)
        );
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      });
    },
    save() {
      let selectedKeyMessages = this.reportRequirements.categoryKeymessage
        .filter((item) => {
          return item.selected;
        })
        .map((item) => {
          return item.id;
        });
      let model = {
        surveyId: this.surveyID,
        userId: this.user.EncrypteduserID,
        importantInfo: this.reportRequirements.importantInfo,
        selectedKeyMessages: selectedKeyMessages,
      };
      const saveSurveyReportRequirements = new Promise((resolve) => {
        wsUtils.SaveSurveyReportRequirements(
          { SaveSurveyReportRequirements: model },
          resolve
        );
      });

      saveSurveyReportRequirements.then((data) => {
        if (data.Success) {
          this.$notification["success"]({
            message: "Well done!",
            description: "Your data are successfully save.",
            placement: "bottomLeft",
            duration: 6,
          });
          this.$router.push("/");
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Something was wrong, please try again or contact our support.",
            placement: "bottomLeft",
            duration: 6,
          });
        }
      });
    },
    resetData() {
      this.reportRequirements = {
        surveyName: null,
        learning_objective: null,
        internal_project: null,
        tags: null,
        costCenter: null,
        userkeymessage: [],
        categoryKeymessage: [],
        importantInfo: null,
      };
    },
  },

  created() {},
  activated() {
    this.resetData();
    this.loading = true;
    this.client = jsUtils.getCurClient();
    this.user = jsUtils.getUserInfo();
    this.surveyID = this.$route.params.id;
    this.surveyReportRequirements();
  },
};
</script>

<style lang="scss" scoped>
.define-report {
  background: #f9f9f9;
  padding: 0 0 60px;
  position: relative;
  h1 {
    margin-bottom: 50px;
    color: #345364;
    font-weight: 600;
    font-size: 23px;
    margin-top: 60px;
  }
  &__item {
    width: 100%;
    max-width: 550px;
    margin: 0 auto 35px;
    text-align: left;
    &:last-child {
      margin-bottom: 0;
    }
    h2 {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      color: #7f8487;
      margin-bottom: 12px;
    }
    label {
      font-weight: 500;
      margin-bottom: 2px;
      font-size: 14px;
      display: block;
    }
    .ant-row {
      margin-bottom: 18px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__item-title {
    margin-bottom: 0 !important;
  }
  &__item-content {
    background: #fff;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
    position: relative;
    &:after {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      content: "";
      opacity: 0.7;
      z-index: 1;
      display: none;
    }
    &.show-overlay {
      &:after {
        display: block;
      }
    }
    &--further-info {
      label {
        max-width: 416px;
        line-height: 18px;
        margin-bottom: 7px;
      }
    }
  }
  &__item-details {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
  &__item-key-messages-wrapper {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
    h3 {
      font-size: 15px;
      color: #2c3e50;
    }
    .general-checkbox {
      margin-bottom: 8px;
      label {
        line-height: 16px;
        font-weight: 400;
      }
      &--disabled {
        label {
          cursor: default;
          &:after {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
</style>
