<template>
  <div class="update-profile">
    <h1>My Profile</h1>

    <div class="update-profile__item">
      <a-row :gutter="16" class="update-profile__item-title">
        <a-col :span="12"><h2>General informations</h2></a-col>
      </a-row>
      <div class="update-profile__item-content">
        <a-row :gutter="16">
          <a-col :span="12"
            ><label class="lineLable">First Name*</label>
            <div class="position-r">
              <Skeleton :borderRadius="4" v-if="showSkeleton"></Skeleton>
              <a-input placeholder="" v-model="profileInfo.firstName" /></div
          ></a-col>
          <a-col :span="12"
            ><label class="lineLable">Last Name*</label>
            <div class="position-r">
              <Skeleton :borderRadius="4" v-if="showSkeleton"></Skeleton
              ><a-input placeholder="" v-model="profileInfo.lastName" /></div
          ></a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12"
            ><label class="lineLable">Email*</label>
            <div class="position-r">
              <Skeleton :borderRadius="4" v-if="showSkeleton"></Skeleton
              ><a-input
                placeholder=""
                v-model="profileInfo.EmailId"
                :class="{
                  'error-field': userInformationsValidation.length > 0,
                }"
              /></div
          ></a-col>
          <a-col :span="12"
            ><label class="lineLable">Company Name*</label>
            <div class="position-r">
              <Skeleton :borderRadius="4" v-if="showSkeleton"></Skeleton
              ><a-input placeholder="" v-model="profileInfo.CompanyName" /></div
          ></a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12"
            ><label class="lineLable">Job title*</label>
            <div class="position-r">
              <Skeleton :borderRadius="4" v-if="showSkeleton"></Skeleton
              ><a-input placeholder="" v-model="profileInfo.jobTitle" /></div
          ></a-col>
          <a-col :span="12"
            ><label class="lineLable">Country*</label>
            <div class="position-r">
              <Skeleton :borderRadius="4" v-if="showSkeleton"></Skeleton
              ><a-select
                class="fullWidth"
                option-filter-prop="children"
                show-search
                :default-value="profileInfo.country"
                :key="selectDropdownsKey"
                @change="selectCountry"
              >
                <a-select-option
                  v-for="country in countries"
                  :key="country.MarketId"
                  :value="country.MarketName"
                >
                  {{ country.MarketName }}
                </a-select-option>
              </a-select>
            </div></a-col
          >
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <ul class="update-profile__validation">
              <li
                v-for="(item, index) in userInformationsValidation"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </a-col>
          <a-col :span="12" class="flex justify-end"
            ><a-button
              type="primary"
              :disabled="saveChangesBtn"
              @click="userDataCheck"
            >
              Save Changes
            </a-button></a-col
          >
        </a-row>
      </div>
    </div>
    <div class="update-profile__item">
      <a-row :gutter="16" class="update-profile__item-title">
        <a-col :span="12"><h2>Change password</h2></a-col>
      </a-row>

      <div class="update-profile__item-content">
        <a-row :gutter="16">
          <a-col :span="8"
            ><label class="lineLable">Current Password*</label
            ><a-input
              placeholder=""
              type="password"
              :class="{ 'error-field': passwordValidation.length > 0 }"
              v-model="currentPassword"
          /></a-col>

          <a-col :span="8"
            ><label class="lineLable">New Password*</label
            ><a-input
              placeholder=""
              type="password"
              v-model="newPassword"
              :class="{ 'error-field': passwordValidation.length > 0 }"
          /></a-col>
          <a-col :span="8"
            ><label class="lineLable">Repeat New Password*</label
            ><a-input
              placeholder=""
              type="password"
              v-model="repeatPassword"
              :class="{ 'error-field': passwordValidation.length > 0 }"
          /></a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <ul class="update-profile__validation">
              <li v-for="(item, index) in passwordValidation" :key="index">
                {{ item }}
              </li>
            </ul>
          </a-col>
          <a-col :span="12" class="flex justify-end"
            ><a-button
              type="primary"
              :disabled="!disableSavePasswordBtn"
              @click="passwordCheck"
            >
              Save Password
            </a-button></a-col
          >
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import _ from "lodash";
import Skeleton from "@/components/general/Skeleton.vue";

export default {
  name: "UpdateProfile",
  components: {
    Skeleton,
  },
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      user: null,
      profileInfo: {
        firstName: "",
        lastName: "",
        EmailId: "",
        CompanyName: "",
        jobTitle: "",
        country: "",
      },
      activeProfileInfo: null,
      saveChangesBtn: false,
      countries: [],
      selectDropdownsKey: 0,
      repeatPassword: null,
      currentPassword: null,
      newPassword: null,
      passwordValidation: [],
      userInformationsValidation: [],
      informationsSuccessUpdated: false,
      passwordSuccessUpdated: false,
    };
  },
  watch: {
    profileInfo: {
      handler(newVal) {
        this.saveChangesBtn = _.isEqual(newVal, this.activeProfileInfo);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    selectCountry(value) {
      this.profileInfo.country = value;
    },
    userDataCheck() {
      const data = {
        firstName: this.profileInfo.firstName,
        lastName: this.profileInfo.lastName,
        EmailId: this.profileInfo.EmailId,
        CompanyName: this.profileInfo.CompanyName,
        jobTitle: this.profileInfo.jobTitle,
        country: this.profileInfo.country,
      };
      const emailFormat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.userInformationsValidation = [];
      if (this.profileInfo.EmailId !== this.activeProfileInfo.EmailId) {
        if (emailFormat.test(this.profileInfo.EmailId)) {
          this.updateUserData(data, "informationsSuccessUpdated");
        } else {
          this.userInformationsValidation.push(
            "The email address you entered is not valid!"
          );
        }
      } else {
        this.updateUserData(data, "informationsSuccessUpdated");
      }
    },

    updateUserData(data, dataType) {
      const userData = {
        userID: this.user.EncrypteduserID,
        ...data,
      };
      const updateUserData = new Promise((resolve) => {
        wsUtils.UpdateUserData(userData, resolve);
      });

      updateUserData.then((data) => {
        if (!data.Success && data.Status === 2) {
          this.passwordValidation.push("Wrong Current Password!");
        }
        if (data.Success && data.Status === 1) {
          this.$notification["success"]({
            message: `Well done!`,
            description: "Information is successfully updated.",
            placement: "bottomLeft",
            duration: 6,
          });

          if (this.profileInfo.EmailId !== this.activeProfileInfo.EmailId) {
            setTimeout(() => {
              jsUtils.clearSetting();
              this.account = {};
              this.$router.push("/login");
            }, 2000);
          } else {
            this.getUserData();
          }
        }
        if (dataType === "passwordSuccessUpdated") {
          this.repeatPassword = null;
          this.currentPassword = null;
          this.newPassword = null;
        }
      });
    },
    passwordCheck() {
      const data = {
        CurrentPassword: this.currentPassword,
        NewPassword: this.newPassword,
      };

      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
      this.passwordValidation = [];
      if (this.newPassword.length < 8)
        this.passwordValidation.push("Must be atleast 8 characters!");
      if (!/\d/.test(this.newPassword))
        this.passwordValidation.push("Must contain atleast 1 number!");
      if (!/[A-Z]/.test(this.newPassword))
        this.passwordValidation.push("Must contain atleast 1 Capital Case!");
      if (!format.test(this.newPassword))
        this.passwordValidation.push(
          "Must contain atleast 1 Special Character!"
        );
      if (this.newPassword !== this.repeatPassword)
        this.passwordValidation.push(
          "New Password and Repeat Password do not match"
        );

      if (this.passwordValidation.length === 0) {
        this.updateUserData(data, "passwordSuccessUpdated");
      }
    },
    getUserData() {
      const userInfo = new Promise((resolve) => {
        wsUtils.GetUserInfo(this.user.EncrypteduserID, resolve);
      });

      userInfo.then((data) => {
        this.profileInfo = data.d[0];
        this.activeProfileInfo = Object.assign({}, data.d[0]);
        this.selectDropdownsKey = 2;
      });

      const countryPromise = new Promise((resolve) => {
        wsUtils.MarketList(resolve);
      });

      countryPromise.then((data) => {
        this.countries = data;
        this.selectDropdownsKey = 1;
      });
    },
  },
  computed: {
    disableSavePasswordBtn() {
      return (
        this.CurrentPassword !== null &&
        this.NewPassword !== null &&
        this.repeatPassword !== null
      );
    },
    showSkeleton() {
      return this.activeProfileInfo === null;
    },
  },
  activated() {
    this.user =
      JSON.parse(localStorage.getItem("studioUser")) ||
      JSON.parse(jsUtils.readCookie("HS-STUDIO"));
    this.getUserData();
  },
};
</script>

<style lang="scss" scoped>
.update-profile {
  background: #f9f9f9;
  padding: 60px 0;
  h1 {
    margin-bottom: 50px;
    color: #345364;
    font-weight: 600;
    font-size: 23px;
  }
  &__item {
    width: 100%;
    max-width: 600px;
    margin: 0 auto 35px;
    text-align: left;
    &:last-child {
      margin-bottom: 0;
    }
    h2 {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      color: #7f8487;
      margin-bottom: 12px;
    }
    label {
      font-size: 14px;
      display: block;
      margin-bottom: 7px;
    }
    .ant-row {
      margin-bottom: 18px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__item-title {
    margin-bottom: 0 !important;
  }
  &__item-title-info {
    color: var(--hsblue);
    text-align: right;
    font-size: 14px;
    svg {
      margin-right: 4px;
      width: 12px;
    }
  }
  &__item-content {
    background: #fff;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
    .error-field {
      border-color: red;
    }
  }
  &__validation {
    padding-left: 13px;
    list-style-type: decimal;
    li {
      font-size: 12px;
      color: red;
    }
  }
}
</style>
